.navbar {
  background-color: transparent;
  font-family: $cotta, Arial, Helvetica, sans-serif;
  padding: 1rem!important;
  position: fixed;
  width: 100%;
  justify-content: center;
  z-index: 1000;

  transition: all 0.3s;

  .nav-logo {
    width: 10rem;
  }

  .nav-logo-mobile {
    width: 10rem;
    display: none;
  }

  .navbar-collapse {
    justify-content: center;
    .navbar-nav {
      align-items: center;
      .nav-link {
        color: $white;
        font-weight: 600;
        margin: 0 0.5rem;
        align-items: center;
      }
    
      .nav-link:hover {
        color: $lightBrown;
      }
  
      .navbar-dropdown {
        position: absolute;
        padding: 0;
        list-style: none;
        background-color: rgb(0, 0, 0, 0.85);
        color: $white;
  
        .dropdown-item {
          padding: 1rem;
          transition: 0.5s all;
        }
  
        .dropdown-item:hover {
          background-color: $darkBrown;
        }
      }

    }
  }

   @include viewport(tablet-down) {
    text-align: center;

    .nav-logo {
      display: none;
    }

    .nav-logo-mobile {
      display: inline;
    }

    .navbar-toggler {
      border: 1px solid $white;

      .navbar-toggler-icon {
        background-image: url('../../icons/hamburger.svg');
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          border: none;
          padding: 20px 0;
        }
        .nav-link:hover {
          border: none;
        }
  
        .navbar-dropdown {
          position: relative;
          background-color: transparent;
          border-bottom: 2px solid white;
          .dropdown-item {
            transition: none;
          }
  
          li:nth-of-type(1) {
            border-top: solid 2px $white;
          }
      }
      }
    }
   }

}


.navbar-bg {
  background-color: rgb(0, 0, 0, 0.85);
  border-bottom: 2px solid $medBrown;
}

.navbar-toggler:focus,
.navbar-toggler:hover,
.navbar-toggler:focus:not(:focus-visible) {
  outline: none;
}
