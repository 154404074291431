.main-banner {
  background-image: url('../../images/home/landing-banner.png');
  background-position: right center;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 5rem;

  .main-banner-inner {
    .mb-desc {
      padding-top: 10rem;
      .mb-text {
        margin-bottom: 4rem;
        .mb-header {
          background-image: url('../../images/home/mb-title.png');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;

          height: 20rem;
          width: 100wh;
        }
        .mb-smalltext {
          font-family: $ashfianaRegular;
          color: white;
          font-size: 30px;
        }
      }
  
      .mb-btn {
        background-image: linear-gradient($lightBrown, $darkBrown);
        color: $white;
        font-family: $ashfianaRegular;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
        font-size: 22px;
        font-weight: 600;
        border: none;
        width: 12rem;
        margin: 0 1rem;
  
        padding: 0.5rem;
  
        transition: 0.5s all;
      }
  
      .mb-img {
        width: 45rem;
        text-align: center;
      }
    }
  
    @include viewport(tablet-down) {
      padding-top: 2rem;
      .mb-desc {
        padding-top: 2rem;
        text-align: center;
  
        .mb-text {
          margin-bottom: 1.5rem;
          .mb-header {
            height: 10rem;
          }
  
          .mb-smalltext {
            font-size: 14px;
          }
  
        }
  
        .mb-btn {
          font-size: 14px;
          max-width: 35%;
        }
  
      }
    }

  }

  @include viewport(mobile-down) {
    background-image: url('../../images/home/home-mobile-bg.png');
    background-position: right center;
    background-size: cover;
    padding-top: 40vh;
    padding-bottom: 5rem;
  }
}

.home-meet-our-team {
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
  background-color: $cream;
  background-image: url('../../images/home/mot-bg.png');
  background-position: center center;
  background-size: cover;
  
  .mot-title-container {
    background-image: url('../../images/home/mot-title.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    height: 20rem;
    width: 20rem;
  }

  .mot-team-container {
    .mot-profile-container {
      margin-bottom: 2rem;

      .mot-team-profile {
        padding-left: 2rem;
        border-left: 3px white solid;

        .mot-img {
          width: auto;
          height: 20rem;
        }

        .mot-team-desc {
          text-align: left;
          font-family: $cotta;
          margin-top: 1rem;
  
          .mot-name {
            font-size: 24px;
            color: $textPrimaryNew;
            margin-bottom: 0;
          }
  
          .mot-mua {
            color: $darkBrown;
            margin-bottom: 0;
          }
        }

        .mot-img {
          width: auto;
          height: 20rem;
        }

        .mot-team-desc {
          text-align: left;
          font-family: $cotta;
          .mot-name {
            font-size: 24px;
            color: $textPrimaryNew;
          }
        }
      }

  
  
    }

  }

  @include viewport(tablet-down) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .mot-title-container {
      margin-bottom: 3rem;
    }

    .mot-team-container {
      .mot-profile-container {
        .mot-team-profile {
          padding-left: 0;
          padding-bottom: 1rem;
          border-left: none;
          border-bottom: 2px solid white;

        }
      }

    }

  }
}

.kenapa-memilih {
  background-image: url('../../images/home/hkm-bg.png');
  background-position: center center;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 5rem;

  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .km-title-container {
    position: relative;
    .km-title {
      background-image: url('../../images/home/hkm-title.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 30rem;
      height: 20rem;

      position: absolute;
      bottom: 1rem;
    }

  }


  .km-content-elmt {
    font-family: $ashfianaRegular;

    .km-elmt-desc {
      text-align: left;
      .km-elmt-title {
        font-size: 25px;
        margin-bottom: 0.5rem;
        color: $white;
        font-size: 32px;
      }

      .km-elmt-text {
        color: $white;
        font-weight: 200;
        font-size: 18px;
      }

      .km-elmt-icon {
        width: 75px;
        height: auto;
        margin-bottom: 1rem;
      }
    }
  }

  @include viewport(tablet-down) {
    padding: 5rem 0;

    .km-title-container {
      text-align: center;
      .km-title {
        position: relative;
        width: 23rem;
        height: 23rem;
      }
    }

    .km-content-elmt {
      justify-content: center;
      .km-elmt-number {
        font-size: 25px;
      }

      .km-elmt-desc {
        .km-elmt-title {
          font-size: 20px;
          margin-bottom: 5px;
        }

        .km-elmt-text {
          font-size: 14px;
        }
      }
    }
  }
}

.elegancy {
  background-image: url('../../images/home/elegancy-bg.png');
  background-position: center center;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;

  .elg-col {
    border-right: 2px solid white;
  }

  .elg-title {
    background-image: url('../../images/home/elegancy-title.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 8rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  .elg-text {
    font-family: $ashfianaRegular;
    color: $darkBrown;
    font-size: 18px;

    text-align: center;
  }

  .elg-img-3 {
    margin-bottom: 1rem;
  }
}

.elegancy-mobile {
  background-image: url('../../images/home/elegancy-bg.png');
  background-position: center center;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;

  display: none;

  .elg-col {
    border-right: 2px solid white;
  }

  .elg-title {
    background-image: url('../../images/home/elegancy-title.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 8rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  .elg-text {
    font-family: $ashfianaRegular;
    color: $darkBrown;
    font-size: 18px;
    text-align: center;
  }

  .elg-img-3 {
    margin-bottom: 1rem;
  }
}

@include viewport(mobile-down) {
  .elegancy {
    display: none;
  }

  .elegancy-mobile {
    display: block;
  }
}