@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@200;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

@font-face {
  font-family: 'Cotta';
  src: url("../fonts/cotta-free.otf") format("opentype");
}

@font-face {
  font-family: 'Ashfiana Oblique';
  src: url("../fonts/Ashfiana Oblique.otf") format("opentype");
}

@font-face {
  font-family: 'Ashfiana Regular';
  src: url("../fonts/Ashfiana Regular.otf") format("opentype");
}