.footer {
  background-image: url('../../images/footer/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin: 0;
  color: $white;
  font-family: $ashfianaRegular;
  text-align: center;

  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);

  .ft-elmts {
    text-align: left;
    align-items: center;
    .ft-logo-img  {
      width: 20rem;
      margin-bottom: 1rem;
    }

    .ft-title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    .ft-text {
      font-weight: 300;
      margin: 0;
    }

    .ft-office {
      border-right: 1px solid white;
    }
  }

  .ft-copyright {
    margin-top: 2rem;
    font-weight: 200;
    font-size: 14px;
  }

  .ft-hr-mobile {
    display: none;
  }

  @include viewport (tablet-down) {
    .ft-logo,
    .ft-office,
    .ft-contact {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .ft-hr-mobile {
      display: block;
    }
  }

  @include viewport(tablet-down) {
    .ft-copyright {
      text-align: center;
    }
  }
 }
