/* utils */
@import '_fonts';
@import '_variables';
@import '_mixins';

/* components */
@import 'components/navbar';
@import 'components/footer';

/* pages */
@import 'pages/home';
@import 'pages/tentang';
@import 'pages/tata-rias';
@import 'pages/tata-busana';
@import 'pages/fotografi';
@import 'pages/pricelist';
@import 'pages/blog';
@import 'pages/galeri';
@import 'pages/kontak';

/* template pages */
@import 'pages/katalog';
@import 'pages/service-gallery';


.gold {
  color: $gold;
}

.hr-title {
  margin-bottom: 3rem;
  border-top: solid 2px $maroon;
  width: 30%;
  text-align: center;
  opacity: 1;
}

.btn {
  font-family: $poppins;
  font-size: 18px;
  text-decoration: none;
  border-radius: 15px;
  padding: 0.75rem 1.5rem;
  transition: 0.5s all;
  border: none;
  font-weight: 600;

  transition: 0.5s all;
}

.btn-maroon {
  background-color: $maroon;
  color: $white;
}

.btn-maroon:hover {
  background-color: #6d0b2c;
  color: white;
}

.btn-transparent {
  border: 2px solid $maroon;
  color: $maroon;
}

.btn-transparent:hover {
  background-color: $maroon;
  color: $white;
}

.capitalize {
  text-transform: capitalize;
}

.maroon {
  color: $maroon!important;
}

.serif {
  font-family: $yeseva;
}

.star-component {
  .star-icon {
    width: 1rem;
    margin-right: 5px;
  }
}

.card-component {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  overflow: hidden;
  margin-left: 1rem;
  margin-right: 1rem;

  font-family: $albert;
  text-align: center;
  
  .card-img {
    overflow: hidden;
    object-fit: cover;
  }

  .card-body {
    padding-bottom: 2rem;
    .card-name {
      font-weight: 600;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
      font-size: 16px;
    }
  
    .card-book-btn {
      background-color: $maroon;
      color: $white;
      border-radius: 10px;
      padding:0.5rem 1.5rem;
      margin-bottom: 2rem;
  
      text-decoration: none;
      transition: all 0.5s;
    }

    .card-book-btn:hover {
      background-color: #6d0b2c;
      color: white;
    }
  }
}

.item-card {
  padding: 0;
  border: none;
  background: transparent;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  max-width: 13.2rem;
  transition: all 0.5s;
  cursor: pointer;

  .item-card-img {
    height: 20rem;
    object-fit: cover;

    transition: 0.3s all;
  }

  .item-card-code {
    font-family: $ashfianaRegular;
    color: #636464;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .item-card-link {
    text-decoration: none;
  
      .item-card-detail {
        display: flex;

        .item-card-detail-text {
          display: flex;
          text-decoration: none;
          font-family: $ashfianaRegular;
          color: $white;
          font-weight: 600;
          font-size: 16px;
          background-image: linear-gradient($lightBrown, $darkBrown);
          padding: 0.5rem 1rem;
          transition: all 0.3s;
        }

        .item-card-detail-text:hover { 
          background-image: linear-gradient(#928676, #4f4433);
        }
  }
  
  }
}

.tbf-kebaya,
.tbf-kain,
.tbf-dodot {
  .item-card-code {
    color: white;
  }
}

.carousel {
  overflow: hidden;
  width: 100%;
  padding-bottom: 7rem;
  
  .carousel-container {
    display: inline-flex;
    position: relative;
    white-space: nowrap;
    margin-right: 2rem;
    margin-bottom: 7rem;
  }

  .carousel__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .carousel-btn-container {
    text-align: center;

    .carousel-btn {
      font-family: $poppins;
      font-weight: 600;
      border: 2px solid $maroon;
      border-radius: 15px;
      text-decoration: none;
      color: $maroon;
      padding: 0.5rem 1.5rem;

      transition: all 0.5s;
    }

    .carousel-btn:hover {
      color: $white;
      background-color: $maroon;
    }
  }

  @include viewport (tablet-down) {
    padding-bottom: 4rem;

    .carousel-container {
      margin-bottom: 3rem;
    }
  }
  
}

.item-detail {
  .id-inner {
    padding-top: 12rem;
    padding-bottom: 5rem;

    .id-header {
      margin-bottom: 1rem;
      text-transform: capitalize;
  
      .id-title {
        font-weight: 300;
        font-size: 28px;
        font-family: $albert;
        color: $darkBrown;
      }

      .id-code {
        font-size: 40px;
        font-family: $cotta;
        color:$textPrimary
      }
    }

    .id-img-container {
      margin-bottom: 2rem;
      .id-img-preview {
        margin-bottom: 0.5rem;
        cursor: pointer;
        border: 2px solid transparent;
        transition: 0.5s all;
      }

      .id-img-preview:hover {
        border: 2px solid $lightBrown;
      }

      .id-img-highlight {
        width: 37rem;
      }
    }
  
    .id-menu {
      .id-hr {
        border-top: 1px solid $lightBrown;
        margin: 0;
        opacity: 1;
      }
  
      .id-menu-item {
        display: flex;
        justify-content: space-between;
  
        .id-menu-btn {
          margin: 1rem 0;
          font-family: $albert;
          font-weight: 600;
          transition: 0.5s all;
          cursor: pointer;
          color: $lightBrown;
          padding: 0.5rem 1rem
        }

        .id-menu-btn:hover {
          color: $medBrown;
          font-weight: 600;
        }

        .id-menu-btn.active {
          color: $white;
          background-image: linear-gradient($lightBrown, $darkBrown);
          padding: 0.5rem 1rem;
        }
      }
    }

    .id-body {
      margin-top: 1rem;
      font-family: $albert;
      font-size: 18px;
      min-height: 30rem;
      white-space: pre-line;
      margin-bottom: 5rem;
    }

    .id-footer {
      .id-wa {
        .id-wa-inner {
          text-decoration: none;
          .id-wa-icon {
            mask-size: 100%;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            mask-position: center;
            width: 24px;
            height: 24px;
            background-color: $textPrimary;
            margin-right: 1rem;
          }
  
          .id-wa-text  {
            font-family: $albert;
            font-weight: 300;
            font-size: 18px;
            color: $textPrimary;
  
          }
        }
      }

      .id-category {
        text-transform: capitalize;
      }
    }
  }
}

#loader {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	text-align: center;
	opacity: 0;
}

#loader .dot {
	width: 12px;
	height: 12px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 5px;
	border: 2px solid #4a4a4a;
}

.pagination {
  color: $darkBrown;
  font-family: $albert;
  margin-top: 2rem;

  .pg-elmt {
    border: 1px solid $darkBrown;
    padding: 7px 15px;
    margin: 0 5px;
    border-radius: 10px;
    transition: 0.5s all;
    cursor: pointer;
  }

  .active {
    background-color: $darkBrown;
    color: $white;
  }

  .disabled {
    color: $textSecondary;
    border: none;
    cursor: auto;
  }

  .pg-elmt:not(.disabled):not(.active):hover {
    background-color: $lightBrown;
    color: white;
  }
}