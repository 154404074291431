
.tata-busana {
  .tb-banner {
    background-image: url('../../images/tata-busana/banner-bg.png');
    background-size: cover;
    background-position: center center;
    padding: 15rem 0;

    .tb-header{
      background-image: url('../../images/tata-busana/tata-busana-title.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      padding: 7rem 0;
    }
  }
  
  .tb-featured-container {
    .tbf-beskap,
    .tbf-songket,
    .tbf-aksesoris {
      background-color: #D5D0C9;

      .tbf-header {
        .tbf-name {
          color: #AB9F8E;
        }
      }
    }

    .tbf-kain,
    .tbf-kebaya,
    .tbf-dodot {
      background-color: #9F9281;

      .tbf-header {
        .tbf-name {
          color: white;
        }
      }
    }

    .tb-featured {
      padding-top: 5rem;
      padding-bottom: 2rem;
      
      .tbf-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        text-transform: capitalize;
  
        .tbf-name {
          font-family: $cotta;
          margin: 0;
          font-size: 80px;
        }
  
        .tbf-link {
          text-decoration: none;
          color: white;
          padding: 0.5rem 1rem;
          transition: 0.5s all;
          font-size: 22px;
          background-image: linear-gradient($lightBrown, $darkBrown);
          font-family: $ashfianaRegular;
          transition: all 0.3s;
        }
        
        .tbf-link:hover {
          color: $white;
          background-image: linear-gradient(#928676, #4f4433);
        }

      }
      .tbf-items {
        margin-bottom: 2rem;
      }
    }
  }

  @include viewport(mobile-down) {
    .tb-banner {
      background-image: url('../../images/tata-busana/banner-bg-mobile.png');

      .tb-header{
        background-image: url('../../images/tata-busana/tata-busana-title.png');
        background-size: contain;
        background-position: center top;
        background-repeat: no-repeat;
        padding: 5rem 0;
        padding: 10rem 0;

        position: relative;
        top: 15rem;
      }
    }
    .tb-featured-container {
      .tbf-beskap,
      .tbf-songket,
      .tbf-aksesoris {
        background-color: #D5D0C9;
  
        .tbf-header {
          .tbf-name {
            color: #AB9F8E;
          }
        }
      }
  
      .tbf-kain,
      .tbf-kebaya,
      .tbf-dodot {
        background-color: #9F9281;
  
        .tbf-header {
          .tbf-name {
            color: white;
          }
        }
      }
  
      .tb-featured {
        padding-top: 5rem;
        padding-bottom: 2rem;
        
        .tbf-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          text-transform: capitalize;
    
          .tbf-name {
            font-family: $cotta;
            margin: 0;
            font-size: 40px;
          }
    
          .tbf-link {
            text-decoration: none;
            color: white;
            padding: 0.5rem 1rem;
            transition: 0.5s all;
            font-size: 14px;
            background-image: linear-gradient($lightBrown, $darkBrown);
            font-family: $ashfianaRegular;
            transition: all 0.3s;
          }
          
          .tbf-link:hover {
            color: $white;
            background-image: linear-gradient(#928676, #4f4433);
          }
  
        }
        .tbf-items {
          margin-bottom: 2rem;
        }
      }
    }
  }
}