.tata-rias {
  .tr-banner {
    background-image: url('../../images/tata-rias/tata-rias-banner-bg.png');
    background-position: center center;
    background-size: cover;
    padding-top: 21rem;
    padding-bottom: 23rem;

    .trb-title {
      .trb-header {
        font-family: $cotta;
        font-size: 100px;
        color: $white;
        text-align: center;
      }
    }

  }

  .tata-rias-intro {
    background-image: url('../../images/tata-rias/tr-intro-bg.png');
    background-position: center center;
    background-size: cover;
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: relative;

    .tr-intro-text {
      margin-top: 2rem;
      font-family: $ashfianaRegular;
      color: $text;
      font-size: 22px;
    }

    .tr-intro-text-img {
      background-image: url('../../images/tata-rias/tr-intro-text.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 15rem;
      width: 50%;

      display: flex;
      float: right;
    }
  }

  .tata-rias-gallery {
    background-color: #a17e4f;
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;

    .trg-title {
      background-image: url('../../images/tata-rias/tr-galeri-title.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 25vh;
      width: 50%;

      display: flex;
      margin: 0 auto;

      margin-bottom: 4rem;
    }

    .trg-img-container {
      .trg-img {
        height: 30rem; 
        width: 100%;
        object-fit: cover;
        object-position: center top;

        box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
        cursor: pointer;
        transition: all 0.3s;
      }

      .trg-img:hover {
        transform: scale(1.15);
      }
    }

    .trg-btn-outer {
      text-decoration: none;
      display: flex;
      margin-top: 3rem;
      justify-content: center;
      transition: all 0.3s;

      .trg-btn {
        padding: 1rem 2rem;
        font-family: $ashfianaRegular;
        font-size: 20px;

        color: white;
        border: 2px solid white;

        transition: all 0.3s;
      }
      .trg-btn:hover {
        background-color: rgb(0, 0, 0, 0.7);
        border: 2px solid rgb(0, 0, 0, 0.1);
      }
    }
  }

  @include viewport (tablet-down) {
    .tr-banner {
      .trb-text {
        padding-top: 30px;

        .trb-header {
          font-size: 25px;
          text-align: center;
          margin-bottom: 15px;
        }

        .trb-smalltext {
          font-size: 16px;
          text-align: center;
          margin-bottom: 25px;
        }
      }

      .trb-btn-container {
        text-align: center;

        .trb-konsul-btn,
        .trb-ig-btn {
          font-size: 14px;
        }
      }
    }

    .tata-rias-intro {
      padding-top: 3rem;
      padding-bottom: 3rem;
  
      .tr-intro-text {
        margin-top: 2rem;
        font-family: $ashfianaRegular;
        color: $text;
        font-size: 22px;
        text-align: center;
      }
  
      .tr-intro-text-img {
        height: 15rem;
        width: 80%;
      }
    }

    .tata-rias-gallery {
      .trg-title {
        width: 85%;
        margin-bottom: 2rem;
      }

      .trg-img-container {
        .trg-img {
          height: 30rem; 
          box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
        }

      }
    }
  }

  @include viewport (mobile-down) {
    .tr-banner {
      background-image: url('../../images/tata-rias/trb-bg-mobile.png');
      background-position: left top;
      background-size: cover;

      position: relative;

      .trb-title {
        position: relative;
        top: 17rem;
      }
      
    }

    .tata-rias-gallery {
      .trg-img-container {
        .trg-img {
          height: 20rem; 
          box-shadow: 1px 3px 5px rgb(0, 0, 0, 0.3);
        }

      }
    }
  }
}

.meet-our-team {
  padding-top: 5rem;
  text-align: center;
  .mot-title {
    font-family: $yeseva;
    color: $maroon;
    font-family: 20px;
    margin-bottom: 5rem;
  }

  .mot-card {
    text-align: left;
    border: none;

    .mot-img-mobile {
      display: none;
    }

    .mot-text {
      font-family: $albert;
      margin-top: 1rem;
      .mot-name {
        color: $textPrimary;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .mot-mua {
        font-weight: 300;
        color: $maroon;
        margin-bottom: 1rem;
      }

      .mot-desc{
        color: #667085;
      }
    }

  }

  .hr-mobile {
    display: none;
    opacity: 1;
    color: $maroon;
  }

  @include viewport (mobile-down) {
    .mot-card {
      .mot-img {
        display: none;
      }
  
      .mot-img-mobile {
        display: block;
        background-size: cover;
        background-position: center center;
        width: 175px;
        height: 175px;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
  
      }

    }
  }

  @include viewport (tablet-down) {
    .hr-mobile {
      display: block;
      margin: 10px 8rem;
    }

    .mot-title {
      font-size: 25px;
      margin-bottom: 2rem;
    }

    .mot-card {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.kenapa-memilih-tata-rias {
  padding-top: 10rem;
  padding-bottom: 10rem;

  .km-title {
    font-family: $yeseva;
    color: $maroon;
    margin-bottom: 3rem;
    text-align: center;
  }

  .km-content {
    text-align: left;
    font-family: $albert;
    .km-elmt-icon {
      margin-bottom: 1rem;
    }
    .km-elmt-title {
      font-size: 24px;
      color: $maroon;
      margin-bottom: 2rem;
    }

    .km-elmt-desc {
      font-size: 18px;
      color: $textSecondary;
    }
  }

  @include viewport (tablet-down) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;

    .km-title {
      font-size: 25px;
    }

    .km-content {
      text-align: center;

      .km-element {
        margin-bottom: 25px;

        .km-elmt-title {
          margin-bottom: 10px;
        }
      }


    }

  }
}