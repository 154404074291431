.pricelist {

  .row {
    --bs-gutter-y: 5rem;
  }

  .pl-banner {
    background-image: url('../../images/pricelist/banner-img.png');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 15rem 0;
  }

  .pl-header {
    background-image: url('../../images/pricelist/header.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 7rem 0;
  }

  .pl-content {
    background-color: #D5D0C8;
    padding: 5rem 0;

    .pl-elmt-container { 
      .pl-elmt {
        min-height: 95%;
        background-color: white;
        position: relative;

        .pl-btn {
          background-image: linear-gradient($lightBrown, $darkBrown);
          font-family: $cotta;
          color: white;
          text-align: center;
          padding: 0.5rem;
          font-size: 24px;
          margin: auto 2rem 2rem 2rem;
        }

        .pl-elmt-header-silver {
          background-image: url('../../images/pricelist/silver-bg.png');
        }
  
        .pl-elmt-header-gold {
          background-image: url('../../images/pricelist/gold-bg.png');
        }
  
        .pl-elmt-header-platinum {
          background-image: url('../../images/pricelist/platinum-bg.png');
        }
        
        .pl-elmt-header {
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 1rem 2rem;
  
          .pl-elmt-paket {
            font-family: $albert;
            color: white;
            font-size: 22px;
            margin-bottom: 0.5rem;
          }
  
          .pl-elmt-name {
            font-family: $cotta;
            font-size: 50px;
            color: $white;
            margin-bottom: 0;
          }
  
        }

        .pl-price {
          background-color: black;
          color: hsl(37, 11%, 52%);
          padding: 0.5rem 1rem;
          font-size: 24px;
          font-family: $albert;
          font-weight: 500;
          margin-bottom: 0;
        }
  
        .pl-body {
          padding: 2rem 3rem;
          .pl-list {
            .pl-list-elmt {
              font-family: $ashfianaRegular;
              color: #746955;
              font-size: 18px;
              max-width: 90%;
            }
  
            .pl-hr {
              border-top: 2px solid #746955;
              opacity: 1;
              padding: 0;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
          }
  
        }
      }

      .pl-btn-container {
        background-color: white;
        padding: 2rem;

        a {
          text-decoration: none;
        }
        .pl-btn {
          background-image: linear-gradient($lightBrown, $darkBrown);
          font-family: $cotta;
          color: white;
          text-align: center;
          padding: 0.5rem;
          font-size: 24px;
  
        }
      }
    }

  }

  @include viewport(mobile-down) {
    .pl-banner {
      background-image: url('../../images/pricelist/banner-img-mobile.png');
      background-position: center top;
      padding: 18rem 0;
    }

    .pl-header {
      position: relative;
      padding: 5rem 0;
      top: 10rem;
    }
  }
}