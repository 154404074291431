$maroon: #AD0E43;
$white: #ffffff;
$lightgray: #E0E3E8;
$gold: #CC9F50;
$gradientGray: linear-gradient(45deg, #F2F2F2, #FFFFFF);

$cream: #D6D0C9;

$textPrimaryNew: #58595b;

$darkBrown: #695a45;
$medBrown: #8c7b65;
$lightBrown: #ad9f8c;

$text: #58595b;
$textLight: #d6d0c8;

$textPrimary: #303030;
$textSecondary : rgb(117, 117, 117);

$albert: 'Albert Sans', Arial, Helvetica, sans-serif;
$yeseva: 'Yeseva One', 'Times New Roman', Times, serif;
$poppins: 'Poppins', Arial, Helvetica, sans-serif;
$cotta: 'Cotta', 'Times New Roman', Times, serif;
$ashfianaOblique: 'Ashfiana Oblique', Arial, Helvetica, sans-serif; 
$ashfianaRegular: 'Ashfiana Regular', Arial, Helvetica, sans-serif; 