.about {
  background-image: url('../../images/tentang/profil-bg.png');
  background-position: right center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 5rem;

  position: relative;

  .ab-img-container {
    position: absolute;
    padding: 3rem;
    right: 0;
    bottom: -10rem;

    .ab-img {
      height: 75%;
    }
  }

  .ab-desc {
    margin-top: 5rem;

    .ab-title {
      font-size: 100px;
      font-family: $cotta;
      color: $lightBrown;
      margin-bottom: 0;
    }
  
    .ab-text {
      margin-left: 5rem;
      font-family: $ashfianaRegular;
      color: $white;
      font-weight: 400;
      font-size: 22px;
      line-height: 35px;
    }


  }

  @include viewport (tablet-down) {
    padding-top: 1rem;
    padding-bottom: 5rem;
    .ab-img-container {
      position: relative;
      padding: 0;
      text-align: center;
    }

    .ab-desc {
      text-align: center;
      margin-top: 5rem;  
      .ab-text {
        margin-left: 0;
      }
  
    }

    .hr-mobile {
      display: block;
    }
  }
}

.visi-misi {
  background-image: url('../../images/tentang/visi-bg.png');
  background-position: right center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 5rem;

  .vm-img-container {
    text-align: center;
  }
  
  .vm-title {
    background-image: url('../../images/tentang/visi-title.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 35rem;
    height: 20rem;
  }

  .vm-visi,
  .vm-misi {
    margin-left: 10rem;
  }

  .vm-visi {
    margin-bottom: 2rem;
  }

  .vm-visi-title,
  .vm-misi-title {
    font-family: $cotta;
    color: $text;
    margin-bottom: 0;
  }

  .vm-visi-text,
  .vm-misi-text {
    font-family: $ashfianaRegular;
    font-size: 22px;
    color: $text;
    line-height: 35px;
  }

  .vm-hr {
    border-top: 3px solid white;
    opacity: 1;
  }

  @include viewport (tablet-down) {
    .vm-title {
      width: 100%;
      height: 20rem;
    }

    .vm-visi,
    .vm-misi {
      margin-left: 0;
      text-align: center;
    }
  }
}

.management {
  background-image: url('../../images/tentang/mgmt-bg.png');
  background-position: right center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 5rem;
  .mg-inner {
    .mg-title {
      font-family: $cotta;
      color: $textLight;
      font-size: 70px;
      margin-bottom: 1rem;
      text-align: center;

      .white {
        color: white;
      }
    }

    .mg-content {
      .mg-img {
        background-image: url('../../images/tentang/mgmt-headshot.png');
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 25rem;
      }

      .mg-profile-list {
        margin-top: 3rem;
        text-align: center;
        .mg-profile {
          font-family: $ashfianaRegular;
          border-left: 1px solid white;
          border-right: 1px solid white;
          .mg-name {
            color: white;
            margin-bottom: 0;
            font-size: 26px;
          }

          .mg-pos {
            color: $textLight;
            font-size: 14px;
          }
        }
      }
    }

  }

  @include viewport(tablet-down) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .mg-inner {
      .mg-title {
        font-size: 50px;
        margin-bottom: 50px;
      }

      .mg-content {
        .mg-img {
          background-image: url('../../images/tentang/mgmt-headshot.png');
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 25vh;
        }
      }
    }
  }
}
