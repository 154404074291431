.fotografi {
  .fb-container {
    background-image: url('../../images/fotografi/fg-banner-bg.png');
    background-position: center center;
    background-size: cover;
    position: relative;

    padding-top: 7rem;
    padding-bottom: 7rem;

    .fb-title {
      background-image: url('../../images/fotografi/fb-title.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      height: 20rem;
      width: 35rem;
  
    }

    .fb-desc {
      color: white;
      font-family: $ashfianaRegular;
      font-size: 24px;
      max-width: 75%;
      margin-left: 1rem;
      margin-bottom: 2rem;
    }

    .fb-btn-container {
      .fb-btn {
        background-image: linear-gradient($lightBrown, $darkBrown);
        color: $white;
        font-family: $ashfianaRegular;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
        font-size: 22px;
        font-weight: 600;
        border: none;
        width: 12rem;
        margin: 0 1rem;
  
        padding: 0.5rem;
  
        transition: 0.5s all;
      }
    }

  
  }

  .fs-container {
    background-image: url('../../images/fotografi/fg-layanan-bg.png');
    background-position: center center;
    background-size: cover;
    position: relative;

    padding-top: 7rem;
    padding-bottom: 7rem;
    position: relative;

    .fs-header {
      color: white;
      font-family: $cotta;
      font-size: 70px;
      max-width: 50%;

      position: absolute;
      bottom: 5rem;
      word-spacing: 9999999px;
    }

    .fs-elmt {
      border-right: 2px solid white;
    }

    .fs-img {
      margin-top: 1rem;
    }

    .fs-book {
      color: white;
      font-family: $ashfianaRegular;
      padding: 0.75rem 1rem;
      background-color: #583f26;
      text-decoration: none;
      margin-bottom: 2rem;

      transition: all 0.3s;
    }

    .fs-book:hover {
      background-color: #2d2013;
    }

    .fs-name {
      font-family: $ashfianaRegular;
      color: white;
      font-size: 30px;
      margin-top: 1rem;
    }
  }

  .fg-gl-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #dacdc1;

    .fg-gl-img-border {
      border-right: 4px solid white;
    }
  }

  @include viewport (tablet-down) {
    .fb-container {
  
      padding-top: 7rem;
      padding-bottom: 2rem;
  
      .fb-title {
        height: 13rem;
        width: 23rem;
    
      }
  
      .fb-desc {
        font-size: 18px;
      }
  
      .fb-btn-container {
        .fb-btn {
          font-size: 16px;
          max-width: 20%;
        }
      }
  
    
    }
    .fs-container {
  
      .fs-header {
        font-size: 60px;
        text-align: center;
        max-width: 100%;
        word-spacing: normal;
        bottom: 0;
        margin-bottom: 2rem;
  
        position: relative;
      }
  
      .fs-elmt {
        border-right: 2px solid white;
        margin-bottom: 2rem;
      }
    }
    
    .fg-gl-container {
      padding-top: 3rem;
      padding-bottom: 3rem;
      background-color: #dacdc1;
  
      .fg-gl-img-border {
        margin-bottom: 1rem;
        border-right: none;
      }
    }
  }

  @include viewport (mobile-down) {
    .fb-container {
      background-image: url('../../images/fotografi/fb-banner-mobile.png');
      background-position: center bottom;
      background-size: cover;
      position: relative;

      padding-top: 12rem;
      padding-bottom: 18rem;
      
      
      .fb-title {
        width: 20rem;
        height: 12rem;
      }

      .fb-desc {
        margin-left: 0;
        max-width: 100%;
        font-size: 18px;
      }

      .fb-btn-container {
        .fb-btn {
          font-size: 18px;
          margin-bottom: 4rem;
          max-width: 40%;
          margin: 0;
          margin-right: 1rem;
        }
      }
    }

    .fs-container {
      .fs-elmt {
        border-right: none;
        margin-bottom: 2rem;
      }

      .fs-elmt-border-mobile {
        border-top: 2px solid white;
        padding-top: 1rem;
      }
    }
  }
}