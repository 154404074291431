.katalog {
  .kt-banner {
    background-size: cover;
    padding: 25rem 0;
  }

  .kt-inner {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .kt-search {
      display: flex;
      margin-bottom: 2rem;

      .kt-search-bar {
        height: 3rem;
        font-family: $albert;
        border: 1px solid $darkBrown;
      }

      .kt-search-btn {
        border: 1px solid $darkBrown;
        background-image: linear-gradient($lightBrown, $darkBrown);
        padding: 0;
        text-align: center;

        transition: 0.3s all;

        .kt-search-icon {
          mask-size: 100%;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          mask-position: center;
          width: 24px;
          height: 24px;
          background-color: $white;
          margin: auto;
        }
      }

      .kt-search-btn:hover {
        background-image: linear-gradient(#928676, #4f4433);
      }
    }
  }
}

.katalog-kebaya {
  .kt-banner {
    background-image: url('../../images/kebaya/kebaya-header.png');
  }
}

.katalog-beskap {
  .kt-banner {
    background-image: url('../../images/beskap/beskap-header.png');
  }
}

.katalog-songket {
  .kt-banner {
    background-image: url('../../images/songket/songket-header.png');
  }
}

.katalog-dodot {
  .kt-banner {
    background-image: url('../../images/dodot/dodot-header.png');
  }
}

.katalog-accessories {
  .kt-banner {
    background-image: url('../../images/aksesoris/aksesoris-header.png');
  }
}

.katalog-kain {
  .kt-banner {
    background-image: url('../../images/kain/kain-header.png');
  }
}

.kt-filter {
  .kt-filter-header {
    font-family: $albert;
    color: $maroon;
    font-size: 18px;
  }

  .kt-hr {
    border-bottom: 1px solid $maroon;
    opacity: 0.5;
  }

  .kt-filter-container {
    .kt-filter-item {
      padding: 10px;
      .kt-filter-btn {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        transition: all 0.5s;
        font-family: $albert;
        margin-bottom: 10px;

        .kt-filter-icon {
          mask-size: 100%;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          mask-position: center;
          width: 20px;
          height: 20px;
          background-color: $textPrimary;
        }
      }

      .kt-filter-elmt {
        // position: absolute;
        // top: 0;
        // left: 0;
        margin-left: 10px;
        width: 100%;
        height: 0;
        overflow: hidden;
        opacity: 0;
        z-index: 1;
        transition: height 3s, opacity 0.5s;
        font-size: 14px;
      }

      .kt-filter-elmt.kt-menu-open {
        height: auto;
        opacity: 1;
      }

    }
  }

  .kt-cari-container {
    text-align: center;
    display: flex;
    justify-content: center;
    border: 2px solid $maroon;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    margin: 20px 10px 2rem 10px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.5s;

    p {
      margin: 0;
      font-family: $albert;
      color: $darkBrown;
    }
  }

  .kt-cari-container:hover {
    background-color: $darkBrown;
    p {
      color: $white;
    }
  }

}

@include viewport (mobile-down) {
  .katalog {
    .kt-banner {
      background-size: cover;
      padding: 35rem 0;
    }
  }
  .katalog-kebaya {
    .kt-banner {
      background-image: url('../../images/kebaya/kebaya-header-mobile.png');
    }
  }

  .katalog-beskap {
    .kt-banner {
      background-image: url('../../images/beskap/beskap-header-mobile.png');
    }
  }
  
  .katalog-songket {
    .kt-banner {
      background-image: url('../../images/songket/songket-header-mobile.png');
    }
  }
  
  .katalog-dodot {
    .kt-banner {
      background-image: url('../../images/dodot/dodot-header-mobile.png');
    }
  }
  
  .katalog-accessories {
    .kt-banner {
      background-image: url('../../images/aksesoris/aksesoris-header-mobile.png');
    }
  }
  
  .katalog-kain {
    .kt-banner {
      background-image: url('../../images/kain/kain-header-mobile.png');
    }
  }
}